@use '@americastestkitchen/mise/mise-styles/mise.scss';

$xlgBreakpoint: 1200px;

// section containers

.setWidthContentBlock {
  margin: auto;
  max-width: calc(100vw - 48px);

  @media screen and (min-width: $xlgBreakpoint) {
    max-width: 1136px;
  }
}

.roseBorderTop {
  border-top: 2px solid #F8D6C7;
  padding-top: 32px;

  @media screen and (min-width: mise.breakpoint(lg)) {
    padding-top: 38px;
  }
}

.freeTrialMarketingAd2 {
  max-width: 100%;
  margin: auto;

  @media screen and (min-width: mise.breakpoint(md)) {
    max-width: calc(100vw - 48px);
  }

  @media screen and (min-width: $xlgBreakpoint) {
    max-width: 1136px;
  }
}

.magazineLink {
  text-align: center;
  @include mise.setFont(proximaNova, null, null);
  font-size: 24px;
  font-weight: 400;
  line-height: 30.9px;
  margin-bottom: 65px;
  a {
    text-underline-offset: 4px;
    font-style: italic;
    text-decoration: underline solid mise.sourceColor(caviar) 1px;
  }
}

$containers: (
  "ccoBrandHero": (
    "mobile": "16",
    "tablet": "24",
    "desktop": "24"
  ),
  "coastToCoastFWContentBlock": (
    "mobile": "28",
    "tablet": "30",
    "desktop": "30"
  ),
  "coastToCoastImageGrid": (
    "mobile": "38",
    "tablet": "41",
    "desktop": "39"
  ),
  "ccoContentLinks": (
    "mobile": "24",
    "tablet": "40",
    "desktop": "40"
  ),
  "freeTrialMarketingAd": (
    "mobile": "41",
    "tablet": "60",
    "desktop": "81"
  ),
  "watchAndCookHeader": (
    "mobile": "27",
    "tablet": "35",
    "desktop": "37"
  ),
  "watchAndCookVideo": (
    "mobile": "10",
    "tablet": "32",
    "desktop": "31"
  ),
  "watchAndCookContent": (
    "mobile": "39",
    "tablet": "32",
    "desktop": "62"
  ),
  "fullWidthImageCarouselWrapper": (
    "mobile": "18",
    "tablet": "38",
    "desktop": "36"
  ),
  "cooksCountryMagazineTextBlock": (
    "mobile": "21",
    "tablet": "35",
    "desktop": "65"
  ),
  "freeTrialMarketingAd2": (
    "mobile": "19",
    "tablet": "14",
    "desktop": "39"
  ),
  "magazineLink": (
    "mobile": "34",
    "tablet": "25",
    "desktop": "33"
  ),
  "tasteOfCooksTextBlock": (
    "mobile": "52",
    "tablet": "27",
    "desktop": "49"
  ),
  "popularRecipesCarouselWrapper": (
    "mobile": "35",
    "tablet": "35",
    "desktop": "60"
  ),
  "newFavoritesCarouselWrapper": (
    "mobile": "62",
    "tablet": "72",
    "desktop": "96"
  ),
  "historicRootsBrandHero": (
    "mobile": "32",
    "tablet": "62",
    "desktop": "89"
  ),
  "historicRootsArticlesCarousel": (
    "mobile": "67",
    "tablet": "42",
    "desktop": "49"
  ),
  "onTheRoadFWVideo": (
    "mobile": "16",
    "tablet": "24",
    "desktop": "32"
  ),
  "onTheRoadTextBlock": (
    "mobile": "23",
    "tablet": "20",
    "desktop": "20"
  ),
  "portraitsTextBlock": (
    "mobile": "15",
    "tablet": "33",
    "desktop": "66"
  ),
  "portraitsGrid": (
    "mobile": "38",
    "tablet": "80",
    "desktop": "65"
  ),
  "toniTiptonFWVideo": (
    "mobile": "16",
    "tablet": "25",
    "desktop": "48"
  ),
  "toniTiptonTextBlock": (
    "mobile": "34",
    "tablet": "63",
    "desktop": "85"
  ),
  "joinUsBrandHero": (
    "mobile": "34",
    "tablet": "60",
    "desktop": "84"
  ),
  "trustOurRecipes": (
    "mobile": "33",
    "tablet": "48",
    "desktop": "53"
  ),
  "bakedFWContentBlock": (
    "mobile": "26",
    "tablet": "23",
    "desktop": "64"
  ),
  "bakedImageGrid": (
    "mobile": "53",
    "tablet": "50",
    "desktop": "20"
  ),
  "teamMemberCarouselItems": (
    "mobile": "72",
    "tablet": "46",
    "desktop": "106"
  ),
  "testimonials": (
    "mobile": "0",
    "tablet": "33",
    "desktop": "0"
  ),
);

.ccoBrandPage {
  color: mise.color(neutral, dark, shade);

  @each $container, $margin in $containers {
    --#{$container}: #{map-get($margin, "mobile")}px;
  }

  @each $container, $margin in $containers {
    .#{$container} {
      margin-bottom: var(--#{$container});
    }
  }
}


@media screen and (min-width: mise.breakpoint(md)) {
  .ccoBrandPage {
    @each $container, $margin in $containers {
      --#{$container}: #{map-get($margin, "tablet")}px;
    }
  }
}

@media screen and (min-width: mise.breakpoint(lg)) {
  .ccoBrandPage {
    @each $container, $margin in $containers {
      --#{$container}: #{map-get($margin, "desktop")}px;
    }
  }
}
