@import '@americastestkitchen/mise/mise-styles/main.scss';

.contentWrapper {
  display: flex;
  justify-content: space-between;

  .jumpLink {
    background-color: transparent;
    padding: 0 $miseSpacingx3;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (min-width: $miseBreakpointMd) {
      margin: 0 $miseSpacingx3;
      padding: 0 $miseSpacingx6;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: $miseBreakpointMd) {
    justify-content: center;
  }
}